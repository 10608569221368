<template>
  <el-dialog
    title="API下载PDF"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="500px"
  >
    <el-table stripe border width="100%" :data="poList">
      <el-table-column prop="po" label="po"></el-table-column>
      <el-table-column prop="status" label="进度状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 9" type="info">下载中...</el-tag>
          <el-tag v-else-if="scope.row.status == 1" type="success"
            >已完成</el-tag
          >
          <el-tag v-else-if="scope.row.status == 0" type="danger"
            >下载失败</el-tag
          >
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { downloadPDF } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      poList: [],
      clearTimeFlag: false,
      i: 0,
      next: -1,
      timeInter: ''
      // returnpolist: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
    // returnpolist: function() {
    //     return this.poList
    // }
  },
  watch: {
    //    poList: function() {
    //        if (this.poList.length > 0) {
    //            for (let i in this.poList) {
    //             if (this.poList[i].status == 9) {
    //             downloadPDF({ po: this.poList[i].po })
    //             .then((data) => {
    //                     this.poList[i].status = data
    //                     // this.returnpolist[i] = this.poList[i]
    //                     // console.log(this.returnpolist)
    //             }).catch((err) => {
    //                 console.log(err)
    //             })
    //             }
    //           }
    //        }
    //    },
    /*  clearTimeFlag: function() {
            if (this.clearTimeFlag) {
                clearInterval(this.timeInter)
                this.clearTimeFlag = false
            }
        },
        poList: function() {
           if (this.poList.length > 0) {
               let i = 0
               let len = this.poList.length
               this.timeInter = setInterval(() => {
                downloadPDF({ po: this.poList[i].po })
                    .then((data) => {
                        this.poList[i++].status = data
                        if (i == len) {
                            this.clearTimeFlag = true
                        }
                    }).catch((err) => {
                    this.clearTimeFlag = true
                    console.log(err)
                    })
                }, 5000);
           }
        }, */
    // 监听上一次请求执行完成，再继续
    next: function () {
      if (this.poList.length > 0) {
        this.download()
      }
    }
  },
  methods: {
    download () {
      const i = this.i
      if (i >= this.poList.length) {
        return
      }
      downloadPDF({ po: this.poList[i].po })
        .then(data => {
          this.poList[i].status = data
          this.i++
          this.next = this.i
        })
        .catch(err => {
          this.i++
          console.log(err)
          this.next = this.i
        })
    },
    show (poList) {
      this.visible = true
      // this.poList = poList
      // this.$set(this.poList, poList)
      // eslint-disable-next-line
      for (let i in poList) {
        // this.$set(this.poList[i], 'po', poList[i].po)
        this.$set(poList[i], 'status', 9)
        this.poList.push(poList[i])
        // this.poList[i].status = 9
      }
      this.returnpolist = this.poList
      this.i = 0 // 重置
      this.next = 0 // watch
    },
    closedone (done) {
      this.poList = []
      done()
    },
    hide () {
      this.poList = []
      this.visible = false
    }
  }
}
</script>
