<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>Wayfair</el-breadcrumb-item>
        <el-breadcrumb-item>销售订单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h2>Wayfair Sales Order</h2>
    </el-row>
    <el-row class="tab">
      <el-tabs v-model="search.step" @tab-click="searchList()">
        <el-tab-pane
          v-for="item in sysConfig.WayfairSaleOrder.step"
          :key="item.key"
          :label="item.val"
          :name="item.key.toString()"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-row class="search">
      <el-col :span="20">
        <el-form :inline="true" size="mini">
          <el-form-item>
            <el-input
              v-model="search.po"
              placeholder="PO: 订单号,多个用逗号分隔"
              type="textarea"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item>
                    <el-select v-model="search.step" placeholder="流程" clearable>
                        <el-option
                        v-for="item in sysConfig.WayfairSaleOrder.step"
                        :key="item.key"
                        :label="item.val"
                        :value="item.key"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
          <el-form-item>
            <el-select v-model="search.status" placeholder="状态" clearable>
              <el-option
                v-for="item in sysConfig.WayfairSaleOrder.status"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="search.warehouse_id"
              placeholder="仓库"
              clearable
            >
              <el-option
                v-for="item in sysConfig.warehouselist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="search.shipment_carrier"
              placeholder="承运商"
              clearable
            >
              <el-option
                v-for="item in this.shipment_carriers"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="search.updatedate"
              @change="setupdateDate()"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="更新开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="search.date"
              @change="setDate()"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="订单开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchList()">搜索</el-button>
            <el-button type="primary" @click="$refs['xlsx-downloader3'].show(search)">下载</el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="handleExport"
              >导出下载</el-button
            >
            <el-button type="primary" @click="downloadLink()"
              >导出下载</el-button
            >
          </el-form-item> -->
        </el-form>
      </el-col>
      <el-col :span="1">
        <el-dropdown>
          <el-button type="primary" size="mini">上传 & 下载 <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="$refs['import-sales-order-dialog'].show()"
              >上传导入销售单</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="$refs['import-sales-order-pdf-dialog'].show()"
              >上传销售单PDF附件</el-dropdown-item
            >
            <el-dropdown-item @click.native="handleExport"
              >下载订单明细</el-dropdown-item
            >
            <el-dropdown-item @click.native="handleExport2"
              >下载出库记录</el-dropdown-item
            >
            <!-- <el-dropdown-item @click.native="downOrder()">API下载订单</el-dropdown-item>
                        <el-dropdown-item @click.native="downPDF()">API下载PDF</el-dropdown-item> -->
            <!-- <el-dropdown-item @click.native="sendOut()">全部发货</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <!-- <el-col :span="1" :offset="1">
                <el-button type="primary" @click="sendOut()" size="mini">发货</el-button>
            </el-col> -->
    </el-row>
    <el-row>
      <el-table
        stripe
        border
        width="100%"
        height="calc(100vh - 458px)"
        :data="salesList"
        @select="handleSelect"
        @select-all="handleSelectall"
      >
        <el-table-column type="selection" width="39"></el-table-column>
        <el-table-column prop="po" label="PO"></el-table-column>
        <el-table-column prop="warehouse" label="仓库"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>
        <el-table-column prop="_status" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">/</span>
            <el-button
              type="text"
              v-if="scope.row.status != 0"
              @click="$refs['edit-sale-remark-dialog'].show(scope.row.po,0,search.step)"
              >{{ scope.row._status }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="shipment_carrier" label="承运商"></el-table-column>
        <el-table-column prop="po_datetime" label="订单日期"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="pushSaleDetail(scope.row.po)"
              >详情</el-button
            >
            <a v-if="scope.row.file != null" :href="getPdf(scope.row.file)" target="_blank" rel="noopener noreferrer"> pdf</a>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 250, 500, 1000]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <el-row>
      <el-col :span="2" v-if="search.step > -1 && search.step < 4">
        <el-dropdown>
          <el-button type="primary" size="small">设置仓库<i class="el-icon-arrow-down el-icon--right"></i></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item,key) in sysConfig.warehouselist"
              :key="key"
              @click.native="setOrderWarehouse(item.id, item.name)">
              {{item.name}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2">
        <el-dropdown v-if="search.step == 1">
          <el-button type="primary" size="small"
            >批量审核<i class="el-icon-arrow-down el-icon--right"></i
          ></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="confirmOrderList(2)"
              >审核通过</el-dropdown-item
            >
            <el-dropdown-item @click.native="confirmOrderList(-1)"
              >审核不通过</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2" v-if="search.step == 2">
        <el-button type="primary" size="small" @click="confirmOrderList(3)"
          >发货出库</el-button
        >
      </el-col>
      <el-col :span="2" v-if="search.step == 3">
        <el-button type="primary" size="small" @click="confirmOrderList(9)"
          >出库审核</el-button
        >
      </el-col>
      <el-col :span="2" v-if="search.step > -1">
        <el-button
          type="primary"
          size="small"
          @click="$refs['edit-sale-remark-dialog'].show(po.join(','), 1, search.step)"
          >批量设置状态与备注</el-button
        >
      </el-col>
      <!-- <el-col :span="2" :offset="2" v-if="search.step == 4">
        <el-button type="primary" size="small"
          @click="$refs['paymentConfirm'].show()"
        >款项确认</el-button>
      </el-col> -->
    </el-row>
    <import-sales-order-dialog
      ref="import-sales-order-dialog"
      @sunccess-import="getSaleList()"
    ></import-sales-order-dialog>
    <import-sales-order-pdf-dialog
      ref="import-sales-order-pdf-dialog"
      @sunccess-import="getSaleList()"
    ></import-sales-order-pdf-dialog>
    <manul-shipments-dialog
      ref="manul-shipments-dialog"
      @save-success="getSaleList()"
    ></manul-shipments-dialog>
    <edit-sale-remark-dialog
      ref="edit-sale-remark-dialog"
      @edit-success="getSaleList()"
    ></edit-sale-remark-dialog>
    <api-download-pdf-dialog
      ref="api-download-pdf-dialog"
    ></api-download-pdf-dialog>
    <edit-sale-dialog
      ref="edit-sale-dialog"
      @edit-success="getSaleList()"
    ></edit-sale-dialog>
    <api-send-out-dialog ref="api-send-out-dialog"></api-send-out-dialog>

    <xlsx-downloader ref="xlsx-downloader" type="SALES_WAYFAIR_ORDER_DETAIL" />
    <xlsx-downloader
      ref="xlsx-downloader2"
      type="SALES_WAYFAIR_STOCK_OUT_RECORD"
    />
    <xlsx-downloader
      ref="xlsx-downloader3"
      type="SALES_WAYFAIR_ORDER"
    />
    <!-- <UploadDialog
      ref="paymentConfirm"
      type="SALES_WAYFAIR_PAYMENT_CONFIRMATION"
    /> -->
  </div>
</template>
<script>
import { getSaleList, confirmList, setWarehouse } from '@/api/amazon'
import importSalesOrderDialog from './import-sales-order-dialog'
import importSalesOrderPdfDialog from './import-sales-order-pdf-dialog'
import manulShipmentsDialog from './manul-shipments-dialog'
import editSaleRemarkDialog from './edit-sale-remark-dialog'
import apiDownloadPdfDialog from './api-download-pdf-dialog'
import apiSendOutDialog from './api-send-out-dialog'
// import saleDetail from './sale-detail'
import editSaleDialog from './edit-sale-dialog'
import { mapGetters } from 'vuex'
import XlsxDownloader from '@/components/xlsx-downloader'
// import UploadDialog from '@/components/upload-dialog'

let fromparams = ''
export default {
  name: 'wayfairSales',
  components: {
    XlsxDownloader,
    importSalesOrderDialog,
    manulShipmentsDialog,
    importSalesOrderPdfDialog,
    editSaleRemarkDialog,
    apiDownloadPdfDialog,
    apiSendOutDialog,
    // saleDetail,
    editSaleDialog
    // UploadDialog
  },
  data () {
    return {
      search: {
        po: '',
        updatedate: '',
        date: '',
        start: '',
        end: '',
        po_start: '',
        po_end: '',
        step: '1',
        status: null,
        warehouse_id: '',
        shipment_carrier: ''
      },
      // tabvalue: '1',
      salesList: [],
      pageData: {
        current_page: 1,
        per_page: 100
      },
      po: [],
      dataPDFlist: [],
      shipment_carriers: [
        { id: 'UPSN', name: 'UPSN' },
        { id: 'FDEG', name: 'FDEG' },
        { id: 'LTL', name: 'LTL' }
      ]
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    // this.getSaleList()
  },
  beforeRouteEnter (to, from, next) {
    if (from.params.filterParams) {
      fromparams = from.params.filterParams
    }
    next()
  },
  beforeMount () {
    if (fromparams.length > 0) {
      const params = JSON.parse(fromparams)
      this.search.po = params.po
      this.search.status = params.status
      // this.search.step = params.step
      this.search.created_at = params.created_at
      this.search.warehouse_id = params.warehouse_id
      this.search.shipment_carrier = params.shipment_carrier
      this.pageData.current_page = params.current_page
      this.pageData.per_page = params.per_page
    }
    this.getSaleList()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'wayfairSalesDetail') {
      from.meta.keepAlive = true
      next()
    } else {
      from.meta.keepAlive = false
      fromparams = ''
      this.$destroy()
      next()
    }
    // next();
  },
  methods: {
    handleExport (params) {
      this.$refs['xlsx-downloader'].show()
    },
    handleExport2 () {
      this.$refs['xlsx-downloader2'].show()
    },
    // 设置日期
    setDate () {
      if (this.search.date == null) {
        this.search.po_start = ''
        this.search.po_end = ''
      } else {
        this.search.po_start = this.search.date[0]
        this.search.po_end = this.search.date[1]
      }
    },
    setupdateDate () {
      if (this.search.updatedate == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search.updatedate[0]
        this.search.end = this.search.updatedate[1]
      }
    },
    // downloadLink () {
    //   const url =
    //     baseUrl +
    //     'wayfair/sale/exportOrder?token=' +
    //     localStorage.getItem('token') +
    //     '&created_at=' +
    //     this.search.created_at +
    //     '&step=' +
    //     this.search.step +
    //     '&status=' +
    //     this.search.status +
    //     '&warehouse_id=' +
    //     this.search.warehouse_id +
    //     '&start=' +
    //     this.search.start +
    //     '&end=' +
    //     this.search.end +
    //     '&po=' +
    //     this.search.po
    //   window.open(url)
    // },
    getSaleList () {
      const jsonData = {
        po: this.search.po,
        created_at: this.search.created_at,
        step: this.search.step,
        status: this.search.status,
        warehouse_id: this.search.warehouse_id,
        shipment_carrier: this.search.shipment_carrier,
        start: this.search.start,
        end: this.search.end,
        po_start: this.search.po_start,
        po_end: this.search.po_end,
        per_page: this.pageData.per_page,
        page: this.pageData.current_page
      }
      getSaleList(jsonData)
        .then(data => {
          this.salesList = data.data
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmOrderList (step) {
      let messg = ''
      let title = ''
      if (step === 2) {
        messg =
          '销售单审核确认后，系统将自动为其创建出库单。如果未勾选指定订单，将审核所有待审核订单，务必谨慎操作。确定审核通过？'
        title = '审核通过'
      } else if (step === -1) {
        messg =
          '如果未勾选指定订单，将审核所有待审核订单，务必谨慎操作。确定取消该订单吗？'
        title = '审核不通过'
      } else if (step === 3) {
        messg = '系统将安排订单发货：下载PDF并创建海外仓出库单。确定执行审核？'
        title = '发货出库'
      } else if (step === 9) {
        messg =
          '系统将尝试审核海外仓出库单。如无勾选指定订单，将审核所有待出库订单，务必谨慎操作。出库审核后的订单将进入财务结算环节不可撤销，确定执行审核？'
        title = '出库审核'
      }
      this.$confirm('提示', { title: title, message: messg })
        .then(_ => {
          confirmList({
            step: step,
            po: this.po
          })
            .then(data => {
              this.po = []
              this.getSaleList()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getSaleList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getSaleList()
    },
    searchList () {
      this.pageData.current_page = 1
      this.getSaleList()
    },
    handleSelect (selection, row) {
      this.po = selection.map(item => {
        return item.po
      })
    },
    handleSelectall (selection) {
      this.po = selection.map(item => {
        return item.po
      })
    },
    pushSaleDetail (po) {
      // const filterParams = {
      //   po: this.search.po,
      //   step: this.search.step,
      //   status: this.search.status,
      //   warehouse_id: this.search.warehouse_id,
      //   created_at: this.search.created_at,
      //   current_page: this.pageData.current_page,
      //   per_page: this.pageData.per_page
      // }
      this.$router.push({
        name: 'wayfairSalesDetail',
        params: {
          po
        }
      })
    },
    getPdf (url) {
      let strUrl = ''
      strUrl = process.env.VUE_APP_API_BASE + '/' + url
      return strUrl
    },
    setOrderWarehouse (wid, wname) {
      if (!this.checkSelected()) {
        return false
      }
      this.$confirm('提示', { title: '提示信息', message: '确定将勾选订单的仓库修改为' + wname + '吗？' })
        .then(_ => {
          setWarehouse({
            po: this.po,
            warehouse_id: wid
          })
            .then(data => {
              this.po = []
              this.getSaleList()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    checkSelected () {
      if (this.po.length === 0) {
        this.$alert('请至少选择一个订单')
        return false
      }
      return true
    }
    // downOrder() {
    //   downloadOrder()
    //   .then((data) => {
    //     //   const a = document.createElement('a')
    //     //     a.style.display = 'none'
    //     //     a.download = 'wayfair订单.pdf'
    //     //     a.href = data;
    //     //     a.click();
    //   }).catch((err) => {
    //       console.log(err)
    //   })
    // },
    // downPDF() {
    //    downloadPDF()
    //   .then((data) => {
    //     //   const a = document.createElement('a')
    //     //     a.style.display = 'none'
    //     //     a.download = 'wayfair.pdf'
    //     //     a.href = data;
    //     //     a.click();
    //     this.dataPDFlist = data
    //     if (this.dataPDFlist) {
    //         this.$refs['api-download-pdf-dialog'].show(this.dataPDFlist)
    //     }
    //   }).catch((err) => {
    //       console.log(err)
    //   })
    // },
    // sendOut() {
    //    shipTo()
    //   .then((data) => {
    //     this.dataPDFlist = data
    //     if (this.dataPDFlist) {
    //         this.$refs['api-send-out-dialog'].show(this.dataPDFlist)
    //     }
    //   }).catch((err) => {
    //       console.log(err)
    //   })
    // }
  }
}
</script>
