<template>
  <el-dialog
    title="手动发货"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="closedone"
    width="500px"
  >
    <el-tag>注意：发货操作只能编辑一次，请务必保证填入的信息准确无误。</el-tag>
    <el-form :inline="true" size="small">
      <el-form-item label="出库单号">
        <el-input v-model="search.outbound_no"></el-input>
      </el-form-item>
      <el-form-item label="出库仓库">
        <el-select v-model="search.warehouse_id">
          <el-option
            v-for="item in sysConfig.warehouselist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="saveManuShip()"
        >Upload</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { manuShip } from '@/api/amazon'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      visible: false,
      search: {
        po: '',
        outbound_no: '',
        warehouse_id: ''
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (po) {
      this.visible = true
      this.search.po = po
    },
    saveManuShip () {
      manuShip(this.search)
        .then(data => {
          this.$emit('save-success')
        })
        .catch(err => {
          console.log(err)
        })
    },
    closedone (done) {
      done()
    }
  }
}
</script>
