<template>
  <el-dialog
    title="上传销售单PDF附件"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="closedone"
    width="600px"
  >
    <el-tag
      >注意：PDF文件名必须以销售单的PO号相同，例如文件abc.pdf对应订单号abc的附件。</el-tag
    >
    <el-form label-position="left">
      <el-scrollbar style="height:300px;">
        <el-form-item label-width="10px">
          <el-upload
            :action="importInboundOrder"
            :limit="500"
            name="file"
            :multiple="true"
            :on-success="onUploadSuccess"
            :before-upload="handleBeforeUpload"
            :on-error="onUploadError"
            :auto-upload="false"
            :show-file-list="true"
            ref="upload"
          >
            <el-button size="small" type="primary">选取pdf文件</el-button>
          </el-upload>
        </el-form-item>
      </el-scrollbar>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="hide">取 消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >上 传</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env'
export default {
  data () {
    return {
      visible: false
    }
  },
  computed: {
    importInboundOrder () {
      return (
        baseUrl +
        'wayfair/sale/uploadPdf?token=' +
        localStorage.getItem('token')
      )
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    closedone (done) {
      done()
    },
    hide () {
      this.visible = false
    },
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('sunccess-import')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
<style scoped>
.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>
